<template>
  <div>
    <!--begin::Row-->
    <div class="row">
      <div class="col-lg-12">
        <redeem-token></redeem-token>
      </div>
    </div>
    <!--end::Row-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RedeemToken from "@/view/pages/tokens/RedeemToken/RedeemToken";

export default {
  name: "MainDashboard",
  components: {
    RedeemToken,
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", currentUserPicture: "auth/currentUserPicture" }),
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("GENERAL.REDEEM") }]);
  },
};
</script>
