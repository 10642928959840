<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title font-weight-bolder">
        {{ $t("GENERAL.REDEEM_TOKEN") | uppercase }}
      </h3>
    </div>
    <hr class="" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1">
        <div class="input-group input-group-xl">
          <div class="input-group-prepend">
            <span class="input-group-text" style="width: 60px">
              <i class="la flaticon2-tag ml-1 mt-1 h2"></i>
            </span>
          </div>
          <input
            ref="preferred_white_label"
            type="text"
            class="form-control form-control-lg text-muted"
            style="height: 60px"
            v-model="token"
            :placeholder="$t('GENERAL.PASTE_TOKEN')"
          />
        </div>
      </div>
      <div class="pt-5">
        <p class="text-center font-weight-normal h5 pb-7 text-info font-weight-bolder">
          {{ $t("GENERAL.STARTS_LAT_TOKEN") }}
        </p>
        <button :disabled="!token" @click.prevent.stop="redeem" class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 btn-lg h3 mt-6 py-6">
          <i class="fa fa-check"></i>
          {{ $t("GENERAL.REDEEM") }}
        </button>
      </div>
    </div>

    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 14-->
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ATTENDEE_TOKENS, REDEEM_TOKEN } from "@/core/services/store/token.module";
import TheToaster from "@/core/services/the-toaster";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "redeem-token",
  data() {
    return {
      token: "",
    };
  },
  mixins: [uppercase],
  components: {},
  methods: {
    redeem() {
      this.$store
        .dispatch("token/" + REDEEM_TOKEN, this.token)
        .then((response) => {
          this.$store.dispatch("token/" + GET_ATTENDEE_TOKENS, { type: "all", page: 0 }).then(() => {});
          TheToaster.success(response.data.message, false);
          this.$router.push({ name: "dashboard" });
        })
        .finally(() => {
          this.token = "";
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
};
</script>
